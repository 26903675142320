import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "app/translations/translation.en-US.json";
import fiTranslation from "app/translations/translation.fi-FI.json";

const resources = {
  en: {
    translation: enTranslation
  },
  fi: {
    translation: fiTranslation
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fi",
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
