import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';

const App = lazy(() => import('App'));
const SimpleReactLightbox = lazy(() => import('simple-react-lightbox'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
